import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import "./orderThankYou.scss";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CMOButton from "../../components/Button/Button";
import Slider from "react-slick";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { v4 as uuidv4 } from 'uuid';

class OrderThankYou extends React.Component {

  constructor(props) {
    super(props);
  }


  
 componentDidMount() {
  window.addEventListener('load', this.handleLoad);
}

componentWillUnmount() { 
 window.removeEventListener('load', this.handleLoad)  
}

  handleLoad(){
    const uniqueId = uuidv4();
    const fbp = document.createElement('script');
    fbp.type = 'text/javascript';
    fbp.async = true;
    fbp.innerHTML = `fbq('track', 'Lead', { 'eventID': '${uniqueId}' } );`;
    document.body.appendChild( fbp );

    const gtm = document.createElement('script');
    gtm.type = 'text/javascript';
    gtm.async = true;
    gtm.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date());gtag('config', 'G-K0QZ3SVWV6', {transport_url: 'https://tagging-server.cmo.hu', first_party_collection: true, send_page_view: true } ); gtag( "event", "Lead", {'x-fb-event_id': '${uniqueId}'});`;
    document.body.appendChild( gtm );
  }

  render(){
  const pageContext = this.props.pageContext;
  const relatedProducts = pageContext.thankYouProducts;

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <NavigateNextIcon
        className={className}
        style={{
          ...style,
          color: "#707070",
          width: 100,
          height: 100,
          padding: 2,
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <NavigateBeforeIcon
        className={className}
        style={{
          ...style,
          color: "#707070",
          width: 100,
          height: 100,
          padding: 2,
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  };

  const relatedProductsSliderSettings = {
    infinite: relatedProducts.length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "related-products-slider",
    lazyload: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          arrows: true,
          infinite: true,
        },
      },
    ],
  };

  const resolveProductCategorySlug = (id) => {
    if (!id) {
      return "";
    }
    return pageContext.productCategories?.find(
      (category) => category._id === id
    )?.slug;
  };

  return (
    <LayoutHeaderFooter
      header_background_color={pageContext.primaryColor}
      header_secondary_color={pageContext.primaryColor}
      header_no_fade={true}
      SEO_title={pageContext.seo.title}
      SEO_description={pageContext.seo.description}
      SEO_keywords={pageContext.seo.keywords}
      main_class={"thank-you-page"}
      addNoFollowMeta={true}
    >
      <a className="back" href={"/araink"}>
        <ArrowBack></ArrowBack> Vissza
      </a>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        children={pageContext.successText}
      />

      {relatedProducts.length ? (
        <div className="related-products">
          <h1>Neked ajánljuk még</h1>
          <Slider {...relatedProductsSliderSettings}>
            {relatedProducts.map((relatedProduct, index) => (
              <a
                href={
                  resolveProductCategorySlug(
                    relatedProduct.productCategory
                  ) + relatedProduct.Slug
                }
                className="related-product"
                key={`related-product-${index}`}
              >
                <img
                  alt={relatedProduct.title}
                  className="related-product-image"
                  src={
                    process.env.GATSBY_CMS_URL +
                    relatedProduct.ImageColorVariant[0].image.url
                  }
                  title={relatedProduct.ImageColorVariant[0].image_description}
                ></img>
                <span className="related-product-name">
                  {relatedProduct.title}
                </span>
                <span className="related-product-description">
                  {relatedProduct.carouselDescription}
                </span>
                <span className="related-product-price">
                  {relatedProduct.carouselPrice}
                </span>
                <span className="related-product-price-gross">
                  {relatedProduct.carouselPriceGross}
                </span>
                <CMOButton
                  variant="contained"
                  background_color={"#5107ff"}
                  text={"Válaszd ezt!"}
                  color={"white"}
                ></CMOButton>
                <ul className="bullet-points">
                  {relatedProduct.carouselBulletPoint.map(
                    (bulletPoint, index) => (
                      <li
                        className="bullet-point"
                        key={`bullet-point-${index}`}
                      >
                        {bulletPoint.text}
                      </li>
                    )
                  )}
                </ul>
              </a>
            ))}
          </Slider>
        </div>
      ) : (
        ""
      )}
    </LayoutHeaderFooter>
  );
}
}

export default OrderThankYou;
